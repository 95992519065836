<template>
  <iq-main>
    <!-- Loader -->
    <iq-loader :loading="loading" />

    <!-- Subloader -->
    <iq-loader :loading="isSaving" />

    <!-- Content -->
    <div class="container" v-if="!loading">
      <!-- Toolbar -->
      <iq-toolbar>
        <template v-slot:left>
          <!-- Back button -->
          <md-button
            class="md-icon-button md-primary margin-right-1"
            @click="redirect({ name: 'Project', params: { id: projectId } })"
          >
            <md-icon>arrow_back</md-icon>
            <md-tooltip md-direction="right">Zurück zum Projekt</md-tooltip>
          </md-button>

          <!-- Title -->
          <div class="md-title">{{ survey.name }}</div>
        </template>

        <template v-slot:right>
          <md-button
            class="md-icon-button md-primary margin-right-1"
            v-if="isComponentActive(['admin'])"
            @click="toggleIsSortingEnabled"
          >
            <md-icon>filter_list</md-icon>
            <md-tooltip md-direction="left">Fragen sortieren</md-tooltip>
          </md-button>

          <md-button
            class="md-icon-button md-primary margin-0"
            @click="handleSaveFields(fields)"
            v-if="isComponentActive(['admin'])"
          >
            <md-icon>save</md-icon>
            <md-tooltip md-direction="left">Fragebogen speichern</md-tooltip>
          </md-button>
        </template>
      </iq-toolbar>

      <!-- Builder -->
      <div class="wrapper">
        <div class="wrapper--left">
          <!-- Question -->
          <md-field>
            <label>Frage für dieses Feld</label>
            <md-input
              required
              v-model="field.field_question"
              :disabled="!isComponentActive(['admin'])"
            />
          </md-field>

          <!-- Type -->
          <md-field>
            <label>Typ für dieses Feld</label>
            <md-select
              required
              v-model="field.field_type"
              :disabled="!isComponentActive(['admin'])"
            >
              <md-option value="text">Textfeld</md-option>
              <md-option value="number">Nummernfeld</md-option>
              <md-option value="select">Auswahlfeld</md-option>
              <md-option value="single_choice">Einfache Auswahl</md-option>
              <md-option value="multiple_choice">Mehrfache Auswahl</md-option>
              <md-option value="range">Schieberegler</md-option>
              <md-option value="yesno">Ja oder Nein</md-option>
            </md-select>
          </md-field>

          <!-- Specials -->
          <!-- Min max -->
          <div class="md-layout md-gutter" v-if="isMinMaxActive">
            <div class="md-layout-item">
              <md-field>
                <label>Minimum</label>
                <md-input
                  v-model="field.field_min_value"
                  type="number"
                  :disabled="!isComponentActive(['admin'])"
                />
              </md-field>
            </div>

            <div class="md-layout-item">
              <md-field>
                <label>Maximum</label>
                <md-input
                  v-model="field.field_max_value"
                  type="number"
                  :disabled="!isComponentActive(['admin'])"
                />
              </md-field>
            </div>
          </div>

          <!-- Options -->
          <md-field v-if="IsOptionsActive">
            <label>Optionen für das Auswahlfeld</label>
            <md-input
              type="text"
              v-model="newOption"
              @keypress.enter="createNewFieldOptionItem"
              :disabled="!isComponentActive(['admin'])"
            />
            <span class="md-helper-text"
              >Bitte angeben und <kbd>Enter</kbd> drücken</span
            >
          </md-field>

          <md-list v-if="field.field_items.length > 0">
            <div v-for="(item, index) in field.field_items" :key="index">
              <md-list-item>
                <span class="md-list-item-text"
                  >{{ index + 1 }}. {{ item }}</span
                >
                <md-button
                  class="md-icon-button md-accent"
                  @click="removeFieldOptionItem(index)"
                >
                  <md-icon>delete</md-icon>
                </md-button>
              </md-list-item>
              <md-divider />
            </div>
          </md-list>

          <!-- Label -->
          <md-field>
            <label>Bezeichnung für dieses Feld</label>
            <md-input
              required
              v-model="field.field_label"
              :disabled="!isComponentActive(['admin'])"
            />
          </md-field>

          <!-- Placeholder -->
          <md-field>
            <label>Platzhalter für dieses Feld</label>
            <md-input
              required
              v-model="field.field_placeholder"
              :disabled="!isComponentActive(['admin'])"
            />
          </md-field>

          <!-- Description -->
          <md-field>
            <label>Beschreibung für dieses Feld</label>
            <md-textarea
              v-model="field.field_helper"
              md-autogrow
              :disabled="!isComponentActive(['admin'])"
            />
          </md-field>

          <!-- Required -->
          <md-field>
            <label>Ist dieses Feld erforderlich?</label>
            <md-select
              v-model="field.field_required"
              :disabled="
                !isComponentActive(['admin']) || field.field_is_conditional
              "
            >
              <md-option :value="true">Erforderlich</md-option>
              <md-option :value="false">Nicht erforderlich</md-option>
            </md-select>
          </md-field>

          <!-- Is conditional -->
          <md-field v-if="isConditionalActive">
            <label>Ist dieses Feld konditional?</label>
            <md-select
              v-model="field.field_is_conditional"
              :disabled="!isComponentActive(['admin'])"
            >
              <md-option :value="true">Feld ist konditional</md-option>
              <md-option :value="false">Feld ist nicht konditional</md-option>
            </md-select>
            <span class="md-helper-text"
              >Ist dieses Feld von einem anderen Feld abhängig?</span
            >
          </md-field>

          <!-- Dependency -->
          <md-field v-if="field.field_is_conditional">
            <label>Abhängigkeit</label>
            <md-select
              v-model="field.field_dependency"
              :disabled="!isComponentActive(['admin'])"
            >
              <md-option
                v-for="item in conditionDependencies"
                :key="item"
                :value="item"
                >{{ item }}</md-option
              >
            </md-select>
            <span class="md-helper-text"
              >Bitte geben Sie an, von welchem Feld dieses Feld abhängig
              ist.</span
            >
          </md-field>

          <!-- Condition -->
          <md-field v-if="field.field_is_conditional">
            <label>Kondition</label>
            <md-input
              v-model="field.field_condition"
              :disabled="!isComponentActive(['admin'])"
            />
            <span class="md-helper-text"
              >Bitte geben Sie an, welcher Wert zutreffen muss.</span
            >
          </md-field>

          <!-- Add field button -->
          <md-button
            class="md-primary"
            :class="
              isConditionalActive
                ? 'button-adjuster-builder'
                : 'button-adjuster'
            "
            @click="createNewSurveyField"
            :disabled="!isComponentActive(['admin'])"
          >
            Feld hinzufügen
          </md-button>
        </div>

        <div class="wrapper--center">
          <div class="survey-holder">
            <!-- Title -->
            <div class="md-title">{{ survey.name }}</div>

            <!-- Description -->
            <div class="md-caption">{{ survey.description }}</div>

            <!-- Form Renderer -->
            <survey-renderer
              :submission="submission"
              :fields="fields"
              is-editable
              @remove-field="removeSurveyField"
              @edit-field="handleFieldEdit"
            />
          </div>
        </div>

        <div
          class="wrapper--right draggable-list"
          :class="isSortingEnabled && 'active'"
        >
          <sortable
            class="draggable-item"
            v-model="sortData"
            v-for="(field, index) in fields"
            :key="field.field_key"
            :index="index"
            drag-direction="vertical"
            @sortend="handleSortEnd"
          >
            <div>
              <md-icon>drag_handle</md-icon>
            </div>

            <div>
              {{ field.field_question }}
            </div>
          </sortable>
        </div>
      </div>
    </div>

    <!-- Edit dialog -->
    <field-edit-dialog
      :is-active="isEditActive"
      :field="editField"
      :dependencies="conditionDependencies"
      @closeDialog="toggleEditDialog"
      @error="
        () =>
          toggleError(
            'Ein Fehler ist während dem Aktualisieren des Feldes aufgetreten'
          )
      "
      @success="handleFieldUpdateSuccess"
    />

    <md-dialog :md-active="deleteDialog">
      <md-dialog-title>Feld löschen?</md-dialog-title>
      <md-dialog-content>Dieses Feld hat bereits Antworten. Falls dieses Feld gelöscht wird, werden auch die Antworten gelöscht.</md-dialog-content>
      <md-dialog-actions>
        <md-button style="margin-right: auto" @click="() => this.deleteDialog = false">Abbrechen</md-button>
        <md-button class="md-accent" @click="() => this.removeSurveyField(this.field_key, true)">Löschen</md-button>
      </md-dialog-actions>
    </md-dialog>

    <!-- Snackbars -->
    <md-snackbar
      class="snackbar-adjuster snackbar-adjuster--error"
      :md-active.sync="isError"
      >{{ errorMessage }}</md-snackbar
    >
    <md-snackbar
      class="snackbar-adjuster snackbar-adjuster--success"
      :md-active.sync="isSuccess"
      >{{ successMessage }}</md-snackbar
    >
    <md-snackbar
      class="snackbar-adjuster snackbar-adjuster--warning"
      :md-active.sync="isRequiredFieldDataMissing"
      >Ein oder meherere erforderliche Felder sind nicht ausgefüllt</md-snackbar
    >
  </iq-main>
</template>

<script>
import loadingMixin from "@/mixins/loadingMixin";
import routingMixin from "@/mixins/routingMixin";
import {
  generateFormField,
  SurveyEngine,
} from "@/services/survey/SurveyEngine";
import SurveyRenderer from "@/components/survey/surveyRenderer";
import {
  createSurveyField,
  deleteSurveyField,
  getSurvey,
  getSurveyFields,
  updateSurveyField,
  getFieldAnswersCount
} from "@/services/api/survey";
import errorMixin from "@/mixins/errorMixin";
import successMixin from "@/mixins/successMixin";
import accessControlMixin from "@/mixins/accessControlMixin";
import FieldEditDialog from "@/components/survey/fieldEditDialog";
import Sortable from "vue-drag-sortable";

export default {
  name: "SurveyBuilder",
  components: { FieldEditDialog, SurveyRenderer, Sortable },
  mixins: [
    loadingMixin,
    routingMixin,
    errorMixin,
    successMixin,
    accessControlMixin,
  ],
  data() {
    return {
      sortData: {},
      projectId: "",
      survey: {},
      field_key: "",
      deleteDialog: false,
      fields: new SurveyEngine().fields,
      submission: {},
      conditionDependencies: [],
      field: {
        field_type: "text",
        field_label: "",
        field_question: "",
        field_helper: "",
        field_placeholder: "",
        field_required: false,
        field_items: [],
        field_validation: "",
        field_min_value: 0,
        field_max_value: 100,
        field_matrix_x: 0,
        field_matrix_y: 0,
        field_is_conditional: false,
        field_dependency: "",
        field_condition: "",
        field_position: 1,
        field_is_active: true,
      },
      isEditActive: false,
      editField: {
        field_type: "text",
        field_label: "",
        field_question: "",
        field_helper: "",
        field_placeholder: "",
        field_required: false,
        field_items: [],
        field_validation: "",
        field_min_value: 0,
        field_max_value: 100,
        field_matrix_x: 0,
        field_matrix_y: 0,
        field_is_conditional: false,
        field_dependency: "",
        field_condition: "",
        field_position: 1,
        field_is_active: true,
      },
      newOption: "",
      isSaving: false,
      isSortingEnabled: false,
      isRequiredFieldDataMissing: false,
    };
  },
  computed: {
    isMinMaxActive() {
      return (
        this.field.field_type === "number" || this.field.field_type === "range"
      );
    },
    IsOptionsActive() {
      return (
        this.field.field_type === "select" ||
        this.field.field_type === "single_choice" ||
        this.field.field_type === "multiple_choice"
      );
    },
    isConditionalActive() {
      return this.fields.length > 0;
    },
  },
  watch: {
    fields(val) {
      this.submission = SurveyEngine.getSubmissionFromFields(val);
      this.conditionDependencies = val.map((item) => item.field_key);
    },
    field: {
      handler(val) {
        if (val.field_is_conditional === true) {
          this.field.field_required = false;
        }
      },
      deep: true,
    },
  },
  async created() {
    this.projectId = this.$route.params.id;
    await this.getProjectSurvey();
  },
  methods: {
    handleSortEnd(e) {
      const { oldIndex, newIndex } = e;
      this.fields.splice(newIndex, 0, this.fields.splice(oldIndex, 1)[0]);
    },
    async getProjectSurvey() {
      try {
        this.survey = await getSurvey(this.projectId);
        this.fields = await getSurveyFields(this.survey.id);
        this.toggleLoading();
      } catch (error) {
        console.error(error);
      }
    },
    handleSaveFields() {
      let fields = this.fields.map((field, index) => {
        return { ...field, field_position: index + 1 };
      });
      this.saveSurveyFields(fields);
    },
    async saveSurveyFields(fields) {
      this.toggleSaving();
      try {
        if (this.fields.length === 0) {
          this.toggleError(
            "Es wurden keine Felder für diesen Fragebogen erstellt"
          );
          this.toggleSaving();
        } else {
          for (let field of fields) {
            if (!field.id) await createSurveyField(this.survey.id, field);
            else await updateSurveyField(field.id, field);
          }
          let updatedFields = await getSurveyFields(this.survey.id);
          this.fields = updatedFields.sort(
            (a, b) => a.field_position - b.field_position
          );
          this.toggleSuccess("Fragebogen wurde erfolgreich gespeichert");
          this.toggleSaving();
        }
      } catch (error) {
        this.toggleError("Fragebogen konnte nicht gespeichert werden");
        this.toggleSaving();
      }
    },
    createNewSurveyField() {
      if (
        !this.field.field_type ||
        !this.field.field_label ||
        !this.field.field_question ||
        !this.field.field_placeholder
      ) {
        this.isRequiredFieldDataMissing = true;
        return;
      }

      let field = generateFormField(this.field);
      this.fields.push(field);
      this.resetFieldData();
    },
    async removeSurveyField(fieldKey, force = false) {
      let fieldToRemove = this.fields.find(
        (field) => field.field_key === fieldKey
      );
      let index = this.fields.findIndex(
        (field) => field.field_key === fieldKey
      );
      if (fieldToRemove.id) {
        const { data: { count }} = await getFieldAnswersCount(fieldToRemove.id);
        if (count > 0 && !force) {
          this.field_key = fieldKey;
          this.deleteDialog = true;
          return;
        } else {
          await deleteSurveyField(this.survey.id, fieldToRemove.id);
          this.fields.splice(index, 1);
          this.toggleSuccess("Feld wurde vom Fragebogen entfernt");
          this.deleteDialog = false;
          return;
        }
      }
      this.fields.splice(index, 1);
      this.toggleSuccess("Feld wurde vom Fragebogen entfernt");
    },
    resetFieldData() {
      this.field.field_type = "text";
      this.field.field_label = "";
      this.field.field_question = "";
      this.field.field_helper = "";
      this.field.field_placeholder = "";
      this.field.field_required = false;
      this.field.field_items = [];
      this.field.field_validation = "";
      this.field.field_min_value = 0;
      this.field.field_max_value = 100;
      this.field.field_matrix_x = 0;
      this.field.field_matrix_y = 0;
      this.field.field_is_conditional = false;
      this.field.field_dependency = "";
      this.field.field_condition = "";
      this.field.field_position = 1;
      this.field.field_standard = "";
    },
    createNewFieldOptionItem() {
      this.field.field_items.push(this.newOption);
      this.newOption = "";
    },
    async removeFieldOptionItem(index) {
      this.field.field_items.splice(index, 1);
    },
    toggleSaving() {
      this.isSaving = !this.isSaving;
    },
    toggleEditDialog() {
      this.isEditActive = !this.isEditActive;
    },
    handleFieldEdit(e) {
      this.editField = this.fields.find((field) => field.field_key === e.key);
      this.editField.field_position = e.position;
      this.toggleEditDialog();
    },
    handleFieldUpdateSuccess(data) {
      this.fields = data.sort((a, b) => a.field_position - b.field_position);
      this.toggleSuccess("Feld wurde erfolgreich aktualisiert");
    },
    toggleIsSortingEnabled() {
      this.isSortingEnabled = !this.isSortingEnabled;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/main";

.button-adjuster-builder {
  margin: 16px 0 0 0;
  width: 100%;
}

.wrapper--left {
  width: 30% !important;
  overflow-y: auto;
}

.wrapper--right {
  display: none;
  width: 20% !important;
  overflow-y: auto;
  padding: $padding;
  border-left: $border-default;
  transition: $transition;

  &.active {
    display: block;
  }
}

.survey-holder {
  background-color: white;
  border: $border-default;
  border-radius: $border-radius;
  padding: $padding;
}

.md-list {
  background-color: transparent;
}

kbd {
  background-color: var(--iq-gray-200);
  color: var(--iq-gray-600);
  padding: 0.2rem;
  border-radius: 0.25rem;
  box-shadow: 1px 1px 1px #777;
}

.md-helper-text {
  bottom: -25px !important;
}

.draggable-list {
  position: relative;
}

.draggable-item {
  padding: $padding;
  border-radius: $border-radius;
  border: $border-default;
  margin-bottom: $margin;
  transition: $transition;
  display: flex;

  &:hover {
    background-color: var(--iq-gray-100);
    cursor: move;
  }

  &:last-child {
    margin-bottom: unset;
  }

  & > div:first-child {
    margin-right: 0.5rem;

    & > .md-icon {
      color: var(--iq-gray-300) !important;
    }
  }
}
</style>
