<template>
  <md-dialog :md-active="isActive">
    <md-dialog-title>Feld bearbeiten</md-dialog-title>

    <md-dialog-content>
      <!-- Question -->
      <md-field>
        <label>Frage für dieses Feld</label>
        <md-input v-model="field.field_question" />
      </md-field>

      <!-- Type -->
      <md-field>
        <label>Typ für dieses Feld</label>
        <md-select v-model="field.field_type">
          <md-option value="text">Textfeld</md-option>
          <md-option value="number">Nummernfeld</md-option>
          <md-option value="select">Auswahlfeld</md-option>
          <md-option value="single_choice">Einfache Auswahl</md-option>
          <md-option value="multiple_choice">Mehrfache Auswahl</md-option>
          <md-option value="range">Schieberegler</md-option>
          <md-option value="yesno">Ja oder Nein</md-option>
        </md-select>
      </md-field>

      <!-- Specials -->
      <!-- Min max -->
      <div class="md-layout md-gutter" v-if="isMinMaxActive">
        <div class="md-layout-item">
          <md-field>
            <label>Minimum</label>
            <md-input v-model="field.field_min_value" type="number" />
          </md-field>
        </div>

        <div class="md-layout-item">
          <md-field>
            <label>Maximum</label>
            <md-input v-model="field.field_max_value" type="number" />
          </md-field>
        </div>
      </div>

      <!-- Options -->
      <md-field v-if="IsOptionsActive">
        <label>Optionen für das Auswahlfeld</label>
        <md-input
          type="text"
          v-model="newOption"
          @keypress.enter="createNewFieldOptionItem"
        />
        <span class="md-helper-text"
          >Bitte angeben und <kbd>Enter</kbd> drücken</span
        >
      </md-field>

      <md-list v-if="field.field_items.length > 0">
        <div v-for="(item, index) in field.field_items" :key="index">
          <md-list-item>
            <span class="md-list-item-text">{{ index + 1 }}. {{ item }}</span>
            <md-button
              class="md-icon-button md-accent"
              @click="removeFieldOptionItem(index)"
            >
              <md-icon>delete</md-icon>
            </md-button>
          </md-list-item>
          <md-divider />
        </div>
      </md-list>

      <!-- Label -->
      <md-field>
        <label>Bezeichnung für dieses Feld</label>
        <md-input v-model="field.field_label" />
      </md-field>

      <!-- Placeholder -->
      <md-field>
        <label>Platzhalter für dieses Feld</label>
        <md-input v-model="field.field_placeholder" />
      </md-field>

      <!-- Description -->
      <md-field>
        <label>Beschreibung für dieses Feld</label>
        <md-textarea v-model="field.field_helper" md-autogrow />
      </md-field>

      <!-- Is conditional -->
      <md-field v-if="isConditionalActive">
        <label>Ist dieses Feld konditional?</label>
        <md-select v-model="field.field_is_conditional">
          <md-option :value="true">Feld ist konditional</md-option>
          <md-option :value="false">Feld ist nicht konditional</md-option>
        </md-select>
      </md-field>

      <!-- Dependencies -->
      <md-field v-if="field.field_is_conditional">
        <label>Abhängikeit</label>
        <md-select v-model="field.field_dependency">
          <md-option v-for="item in dependencies" :key="item" :value="item">
            {{ item }}
          </md-option>
        </md-select>
      </md-field>

      <!-- Condition -->
      <md-field v-if="field.field_is_conditional">
        <label>Kondition</label>
        <md-input v-model="field.field_condition" />
      </md-field>

      <!-- Required -->
      <md-field>
        <label>Ist dieses Feld erforderlich?</label>
        <md-select
          v-model="field.field_required"
          :disabled="field.field_is_conditional"
        >
          <md-option :value="true">Erforderlich</md-option>
          <md-option :value="false">Nicht erforderlich</md-option>
        </md-select>
      </md-field>

      <!-- Active -->
      <md-field>
        <label>Ist dieses Feld aktiv?</label>
        <md-select
          v-model="field.field_is_active"
        >
          <md-option :value="true">Aktiv</md-option>
          <md-option :value="false">Inaktiv</md-option>
        </md-select>
      </md-field>
    </md-dialog-content>

    <md-dialog-actions>
      <md-button @click="closeDialog">Abbrechen</md-button>
      <md-button class="md-primary" @click="handleUpdate"
        >Aktualisieren</md-button
      >
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import { updateSurveyField } from "@/services/api/survey";
import resetMixin from "@/mixins/resetMixin";

export default {
  name: "fieldEditDialog",
  mixins: [resetMixin],
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    dependencies: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      newOption: "",
    };
  },
  computed: {
    isMinMaxActive() {
      return (
        this.field.field_type === "number" || this.field.field_type === "range"
      );
    },
    IsOptionsActive() {
      return (
        this.field.field_type === "select" ||
        this.field.field_type === "single_choice" ||
        this.field.field_type === "multiple_choice"
      );
    },
    isConditionalActive() {
      return this.dependencies.length > 1;
    },
  },
  methods: {
    createNewFieldOptionItem() {
      this.field.field_items.push(this.newOption);
      this.newOption = "";
    },
    removeFieldOptionItem(index) {
      this.field.field_items.splice(index, 1);
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    handleUpdate() {
      if (this.field.id) this.updateField(this.field.id);
      else this.closeDialog();
    },
    async updateField(id) {
      try {
        let response = await updateSurveyField(id, this.field);
        this.$emit("success", response.data);
        this.closeDialog();
        this.reset();
      } catch (error) {
        console.error(error);
        this.$emit("error");
        this.closeDialog();
        this.reset();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.md-dialog-content {
  width: 750px;
}

kbd {
  background-color: var(--iq-gray-200);
  color: var(--iq-gray-600);
  padding: 0.2rem;
  border-radius: 0.25rem;
  box-shadow: 1px 1px 1px #777;
}
</style>
